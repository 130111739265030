/* eslint-disable no-unused-vars */

import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ExtLink } from "../components/atoms"
import Contact from "../components/contact"
import PublicationsGrid from "../components/publicationsGrid"

import { Heading } from "../components/atoms"
import ProjectGrid from "../components/projectGrid"
import SkillGrid from "../components/skillGrid"
import skills from "../data/skills"

const SkillsList = () => {
  return (
    <section>
      <div className="mt-2 flex flex-col space-y-0">
        {skills.map(data => {
          return (
            <SkillGrid
              key={data.label}
              heading={data.label}
              description={data.description}
              categories={data.categories}
            />
          )
        })}
      </div>
    </section>
  )
}

const About = _ => {
  return (
    <Layout activePage="about">
      <Seo title="About" />
      <div className="container">
        <article className="prose-lg">
          <h2 className="relative mt-12 mb-3 text-2xl font-bold text-accent">About </h2>
          <h3 className="relative mt-6 mb-2 text-lg font-semibold text-primary">Research Abstract</h3>
          <p>Silicon wafer based photovoltaic technology dominates the global PV market, accounting for over 90 % of total production. Light trapping via surface texturing is a key design element used in Si PV cells to maximise energy conversion efficiency.</p>
          <br />
          <p>Conventionally textured Si surfaces with micron-scale features yield reflectance values of 10-23 % for monocrystalline and multicrystalline Si, respectively. However, lower reflectance values and improved efficiency can be obtained using nanotextured surfaces, where texture dimensions are comparable to the wavelength of the incident light. </p>
          <br />
          <p>This project will focus on industry-oriented research into an atmospheric dry etch (ADE) process for producing nanotextured Si surfaces. </p>
          <h3 className="relative mt-6 mb-2 text-lg font-semibold text-primary">
            Research Goals
          </h3>
          <p>The project will determine optimum process parameters for monocrystalline and multicrystalline silicon by correlating measurements of the surface texture and reflectance of the nanotextured surfaces.</p>
          <ul className="p-3 mt-1 mb-1 list-disc list-inside">
            <li className="mb-1">
             An automated wafer reflectance mapping tool and a laser beam induced current (LBIC) instrument for investigating surface reflectance and photo-carrier lifetime with 100 micron resolution will be developed during the project. 
            </li>
            <li className="mb-1">
             Solar cell-like devices will be fabricated to investigate the effect of the surface texture on the subsequent emitter diffusion, surface passivation and metallisation steps in the cell fabrication scheme. 
            </li>
            <li className="mb-1">
             The project outcomes will provide Nines PV with valuable information for optimising the performance of their commercially available ADE-100 and ADE-6000 process tools.
            </li>
          </ul>
          <h3 className="relative mt-6 mb-2 text-lg font-semibold text-primary">Atmospheric Dry Etch</h3>
          <p>ADE is an industrially viable process, developed by Irish SME <ExtLink link="http://nines-pv.com" newTab>Nines PV</ExtLink>, that displays competitive advantages over other available technologies such as reactive ion etching and metal-assisted etching. Silicon etching is performed at atmospheric pressure, without the requirement for vacuum generation, allowing for fast and continuous inline processing. It is a thermal process, which does not involve plasma, and has a small footprint compared to current wet-etch systems. </p>
          <h3 className="relative mt-6 mb-2 text-lg font-semibold text-primary">About This Website</h3>
          <p>Unless otherwise noted, all contents published, distributed or shared on this website is licenses under <ExtLink link="https://spdx.org/licenses/CC-BY-SA-4.0.html" newTab>CC-BY-SA 4.0</ExtLink>. Please share them with your colleagues if you find these contents helpful.</p>
          {/* <SkillsList /> */}
          <h2 id="publications" className="relative mt-12 mb-6 text-2xl font-bold text-accent">
            Publications
            </h2>
          <PublicationsGrid />
          <Heading>Projects</Heading>
          <p className="-mt-3 mb-8 text-tertiary">
            Projects related to this research that might be of interest
          </p>
          <ProjectGrid />
        </article>
      </div>
      <Contact />
    </Layout>
  )
}

export default About
