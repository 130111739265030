const data = [
  {
    slug: "https://solar.tudubl.in/#refl_measurement",
    title: "3D Printer based reflectance measurement",
    description: "Using low cost 3D printers and open source software in solar cell reflectance mapping",
    year: "2021",
    links: [
    ],
  },
  // {
  //   slug: "https://wiki.reckoning.dev/",
  //   title: "My Digital Garden wiki page",
  //   description: "A wiki page to store eberuthing I know, my notes and research.",
  //   year: "2020",
  //   links: [
  //     {
  //       label: "GitHub",
  //       url: "https://github.com/sadanand-singh/wiki.reckoning.dev",
  //     },
  //     {
  //       label: "website",
  //       url:
  //         "https://wiki.reckoning.dev/",
  //     },
  //   ],
  // },
  // {
  //   slug: "https://github.com/sadanand-singh/ludo-python",
  //   title: "Game of Ludo",
  //   description: "Game of Ludo implemented in Python using PyQt",
  //   year: "2018",
  //   links: [
  //     {
  //       label: "GitHub",
  //       url: "https://github.com/sadanand-singh/ludo-python",
  //     },
  //   ],
  // },
  // {
  //   slug: "https://reckoning.dev/",
  //   title: "My Blog",
  //   description: "My blog in gatsby, react, plotly etc.",
  //   year: "2021",
  //   links: [
  //     {
  //       label: "Website",
  //       url: "https://reckoning.dev/",
  //     },
  //     {
  //       label: "GitHub",
  //       url: "https://github.com/sadanand-singh/reckoning.dev.gatsbyjs",
  //     },
  //   ],
  // },
]

export default data
